<template>
<!-- zx 2021-6-1 -->
<div>
  <!-- <div class="bg_1" v-lazy:background-image="require('./Museum details_img/img(5).png')"> -->
  <div class="bg_1">
    <div class="start">
   <img v-lazy ="require('./Museum details_img/VZXcNe9z.webp')" class="start-img">
 </div>
 <div class="bannert">
   <div class="wri1">博物馆营销</div>
    <div class="wri2">探索打通线上线下融合的营销渠道，推动博物馆文创更精准，更灵活，更便捷地“飞入寻常百姓家”</div>
 </div>
    
  </div>
  <div class="bg_2">
     <div class="wri3">全流程策展</div>
     <div class="wri4">一流服务全流程，做展览，我们是认真的</div>
     <div class="img-1">
       <img
      v-lazy="require('./Museum details_img/k2ZhSXKj.webp')" class="wri5">
      </div>
     <div class="wri11 mt40">
       <div class="wri6">
         <div class="wri12">
           <div class="wri7">前期咨询</div>
         <img src="./Museum details_img/img_5.png"  class="wri8">
         </div>
         <div class="wri9">不确定布展内容？做一场满分展览需要哪些前期准备？长江日报策展中心将为你提供全方位的服务</div>
       </div>
        <div class="wri6 ml30">
         <div class="wri12">
           <div class="wri7">撰写大纲</div>
         <img src="./Museum details_img/img_6.png"  class="wri30">
         </div>
         <div class="wri9">长江日报利用自身的资源，充分挖掘场馆内在价值，让其“衔着金钥匙”出生，专业记着策划大纲、整理资料</div>
       </div>
        <div class="wri6 ml30">
         <div class="wri12">
           <div class="wri7">创意设计</div>
         <img src="./Museum details_img/pic_7.png"  class="wri30">
         </div>
         <div class="wri9">以前期策划方案及大纲为蓝本，以客户真实需求为导向，专业的设计团队，用国际化的视野与本体化元素的融合为客户定制设计。</div>

       </div>
     </div>
       <div class="wri11 mt40">
       <div class="wri6">
         <div class="wri12">
           <div class="wri7">工程施工</div>
         <img src="./Museum details_img/img_2.png"  class="wri30">
         </div>
         <div class="wri9">专业的施工团队，打造专业的展览场景</div>
       </div>
        <div class="wri6 ml30">
         <div class="wri12">
           <div class="wri7">布展陈列</div>
         <img src="./Museum details_img/img_3.png"  class="wri30">
         </div>
         <div class="wri9">作为国企的武汉长江日报博览有限公司，为国内各展示空间提供专业布展及周边延伸产品开发服务。</div>
       </div>
        <div class="wri6 ml30">
         <div class="wri12">
           <div class="wri7">后期运营</div>
         <img src="./Museum details_img/img_4.png"  class="wri30">
         </div>
         <div class="wri9">展览开放后，长江日报策展中心可为业主提供奖项申报、宣传报道、运营维护等增值服务</div>

       </div>
     </div>
  </div>
  <div class="bg_3">
    <div class="wri14">纪念品设计及营销策划</div>
    <div class="wri15">一流的制作全流程，做设计，我们是认真的</div>
    <div class="img-2">
      <img v-lazy="require('./Museum details_img/gO2JBRgB.webp')" class="wri16">
    </div>
    <div class="wri17 mt30">
      <!-- <div class="wri18 " v-lazy:background-image="require('./Museum details_img/img.png')"> -->
      <div class="wri18 ">
        <div class="wri19">意</div>
        <div class="wri20">区域性文化的形象体现。承载本地自然人文资源信息元素，对于特定地域文化的概括与表达。</div>
      </div>
       <!-- <div class="wri21 ml30" v-lazy:background-image="require('./Museum details_img/img1.png')"> -->
        <div class="wri21 ml30">
         <div class="wri19">情</div>
        <div class="wri20">激起游客的喜爱之情，让旅游者产生强烈的购买欲望。</div>
      </div>
       <!-- <div class="wri22 ml30"  v-lazy:background-image="require('./Museum details_img/img2.png')"> -->
       <div class="wri22 ml30">
        <div class="wri19">奇</div>
        <div class="wri20">展现独特的地方性与个性，充分满足旅游者的好奇心，探索心，体验心。</div>
      </div>
       <!-- <div class="wri23 ml30" v-lazy:background-image="require('./Museum details_img/img3.png')"> -->
        <div class="wri23 ml30">
        <div class="wri19">质</div>
        <div class="wri20"> 狠把质量关，追求文化品味与品质，制作材美工巧的文化艺术品。</div>
      </div>
       <!-- <div class="wri24 ml30" v-lazy:background-image="require('./Museum details_img/img4.png')"> -->
       <div class="wri24 ml30">
        <div class="wri19">巧</div>
        <div class="wri20">用尽心思而不着痕迹，从外形，色彩，用材，工艺等方面将旅游地的特色以讨人喜欢的形式表现。</div>
      </div>
    </div>
  </div>
  <!-- <div class="bg_4">
    <img src="./Museum details_img/img(3).png" class="wri26">
  </div> -->

<div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.bg_1{
   height: 600px;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
}
.start-img{
   height: 600px;
}

.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
     width: 100%;
	}
}
.bannert{
  position: absolute;
    left: 361px;
    top: 230px;
}
.wri1{
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // padding-top: 230px;
  // padding-left: 360px;
  text-align: left;
}
.wri1{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.wri2{
  width: 537px;
   color: rgba(255, 255, 255, 1);
  font-size: 24px;
  margin-top: 24px;
  // padding-left: 360px;
  text-align: left;
}
.wri2{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.bg_2{
   margin: auto;
   margin-bottom: 100px;
}
.wri3{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  margin-top: 80px;
}
.wri4{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-top: 24px;
}
.wri5{
  width: 1200px;
  height: 350px;
  margin-top: 60px;
}
.img-1{
  width: 1200px;
  height: 350px;
  margin: auto;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 60px;
		height: 60px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 1200px;
  height: 350px;
	}
}
.wri6{
  width: 380px;
  height: 225px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
}
.wri7{
   color: #333333;
   font-size: 28px;
   margin-top: 36px;
   margin-left: 36px;
   text-align: left;
}
.wri8{
  width: 110px;
  height: 74px;
  padding-left: 96px;
}
.wri30{
  width: 131px;
  height: 74px;
  padding-left: 100px;
}
.wri11{
  display: flex;
  justify-content: center;
  align-items: center;

}
.wri12{
  display: flex;
}
.mt40{
  margin-top: 40px;
}
.wri9{
  color: #999999;
  font-size: 16px;
  width: 308px;
  margin-left: 26px;
  margin-top: 13px;
  text-align: left;
}
.wri10{
  color: #999999;
  font-size: 16px;
  width: 308px;
  margin-left: 26px;
  text-align: left;
}
.ml30{
  margin-left: 30px;
}
.bg_3{
  margin: auto;
  background-color: #F6F8FB;
  padding-bottom: 100px;
}
.wri14{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
}
.wri15{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-top: 24px;
}
.wri16{
  height: 350px;
  width: 1200px;
}
.img-2{
  height: 350px;
  width: 1200px;
   
  margin: auto;
  margin-top: 60px;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 60px;
		height: 60px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		  height: 350px;
    width: 1200px;
	}
}
.wri17{
  display: flex;
  justify-content: center;
}
.wri18{
  width: 216px;
  height: 213px;
  background: url("./Museum details_img/img.png");
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.wri19{
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  padding-top: 36px;
  padding-left: 24px;
  text-align: left;
}
.wri20{
  color: #ffffff;
  font-size: 14px;
  margin-top: 20px;
  padding-left: 24px;
  width: 168px;
  text-align: left;
}
.wri21{
  width: 216px;
  height: 213px;
  background: url('./Museum details_img/img1.png');
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.wri22{
  width: 216px;
  height: 213px;
  background: url('./Museum details_img/img2.png');
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.wri23{
  width: 216px;
  height: 213px;
  background: url('./Museum details_img/img3.png');
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.wri24{
  width: 216px;
  height: 213px;
  background: url('./Museum details_img/img4.png');
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.mt30{
  margin-top: 30px;
}
.bg_4{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 247px;
}
.wri26{
  height: 47px;
  width: 398px;
}
</style>